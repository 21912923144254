// Here you can add other styles
// @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap");

$textColor: #767676;
$grey: #333;
$themeColor: #07b8e1;
$borderColor: #d6dee1;
$blueBorderColor: #daedf6;
$errorColor: #f16667;
$mediumBlue: #2c4199;
$deepBlue: #1b2530;
$white: #fff;
$black: #000;

// .modal-backdrop.show {
//   width: 100%;
//   height: 100%;
// }

// .text-primary {
//   color: $themeColor;
// }

// body {
//   background-color: #f6f7fc;
// }

// body,
// button,
// input,
// optgroup,
// select,
// textarea,
// pre {
//   font-family: "Mulish", sans-serif;
// }

// iframe {
//   display: none !important;
// }
// div iframe {
//   display: block !important;
// }

// .csr-pointer {
//   cursor: pointer !important;
// }

// label {
//   margin-bottom: 5px;
//   color: $textColor;
//   font-size: 14px;
//   letter-spacing: 0.3px;
// }

// .form-control {
//   color: $grey;
//   letter-spacing: 0.3px;
//   height: 36px;
//   padding: 4px 8px !important;
//   border-radius: 10px;
//   font-size: 14px;
//   border-color: $borderColor;
//   height: 48px;

//   &:focus {
//     border-color: $themeColor;
//   }
// }

// textarea.form-control {
//   padding: 4px 8px !important;
//   border-radius: 10px;
//   border-color: $borderColor;
// }

// .card label {
//   font-size: 14px;
//   letter-spacing: 0.3px;
// }

// .form-group {
//   margin-bottom: 15px;
// }

// select {
//   padding-left: 6px !important;
//   background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat right 3px center;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;

//   option {
//     padding: 0;
//   }
// }

// .btn {
//   outline: 0 !important;
//   box-shadow: none !important;
//   font-size: 14px;
//   letter-spacing: 0.3px;
//   padding: 0 12px;
//   border-radius: 8px;
//   text-transform: initial;
//   font-family: "Mulish", sans-serif;
//   font-weight: 800;
// }

// .themeBtn,
// .saveBtn,
// .themeBtnOutline {
//   background-color: $themeColor !important;
//   color: $white !important;
//   height: 45px;
//   line-height: 45px;
//   padding: 0 30px;
//   margin: 15px 0;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $themeColor !important;
//     border-color: $themeColor !important;
//   }
// }

// .resetBtn {
//   height: 44px;
//   line-height: 44px;
//   background: $white !important;
//   border-radius: 10px;
//   color: #609dc2 !important;
//   margin: 0;
//   margin-right: 15px;
//   width: 50px;
//   font-size: 20px;
//   padding: 0 5px;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $white !important;
//     color: #609dc2 !important;
//   }

//   img {
//     width: 32px;
//   }
// }

// .assignCounter {
//   .input-group {
//     overflow: visible;
//     height: auto;
//     .css-1pahdxg-control {
//       height: auto;
//       border: none !important;
//       box-shadow: none !important;
//     }
//   }
// }

// .themeBtnOutline {
//   background-color: $white !important;
//   color: $themeColor !important;
//   border: 1px solid $themeColor;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $white !important;
//     color: $themeColor !important;
//     border: 1px solid $themeColor !important;
//   }
// }

// .custom-control-label::before,
// .custom-control-label::after {
//   top: 3px;
// }

// .custom-control-input:checked~.custom-control-label::before {
//   border-color: $mediumBlue;
//   background-color: $mediumBlue;
// }

// .modal-content {
//   border: 0;
//   box-shadow: 0 0 12px 0 rgba($black, 0.12);
// }

// .modal-footer {
//   padding: 10px 12px 20px;
//   background-color: $white;
//   border-top: 0;
//   justify-content: center;
// }

// .modal-header {
//   display: flex !important;
//   // flex-direction: column-reverse;
//   align-items: center;
//   padding: 12px 15px 10px;
//   position: relative;
//   background-color: $white;
//   border-bottom: 0;
// }

// .modalBtnCancel,
// .modalBtnSave {
//   color: #999 !important;
//   background-color: $white !important;
//   border: 1px solid #999 !important;
//   margin: 0 10px !important;
//   height: 44px;
//   line-height: 42px;
//   font-size: 16px;
//   border-radius: 8px;
//   text-transform: capitalize;
//   padding: 0 20px;
//   font-weight: 700;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $white !important;
//     color: $textColor !important;
//   }
// }

.themeColor {
  color: $mediumBlue !important;
}

// .modalBtnSave {
//   color: $white !important;
//   background-color: $themeColor !important;
//   border-color: $themeColor !important;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $themeColor !important;
//     color: $white !important;
//   }
// }

// .modalBtnright {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;

//   button {
//     height: 36px;
//     padding: 0 14px !important;
//   }
// }

// .pgTitleWrap {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;

//   h4 {
//     margin: 0;
//     font-weight: 800;
//     letter-spacing: 0.3px;
//     color: $mediumBlue;
//     font-size: 20px;

//     span {
//       display: block;
//       font-size: 16px;
//       font-weight: 300;
//       color: $textColor;
//       line-height: 1;
//     }

//     .spinner-border {
//       width: 1rem;
//       height: 1rem;
//       margin-left: 6px;
//       border-width: 0.15em; &::before{
//         content: '/f2b9' !important;
//         font-family: FontAwesome !important;
//         position: absolute !important;
//         right: 0 !important;
//         top: 0 !important;
//         width: 10px !important;
//         height: 10px !important;
//         background-color: red !important;
//         z-index: 99 !important;
//         opacity: 1 !important;
//         display: block !important;
//         background-image:none !important;
//     }
//     }
//   }
// }

// .table-responsive {

// }

// .authPgWrapper {
//   height: 100vh;
//   width: 100%;
//   background: $white;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .logoWrap {
//   background: #f7f7f7;
//   padding: 1%;
//   width: 48%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;

//   .projectLogo {
//     width: 200px;
//     align-self: flex-start;
//     position: absolute;
//     top: 20px;
//     left: 20px;
//   }

//   .authImg {
//     margin-top: auto;
//     margin-bottom: auto;
//     width: 100%;
//     max-width: 500px;
//   }
// }

// .authFormWrap {
//   width: 52%;
//   padding: 0 25px;
//   border-radius: 15px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100%;

//   h3 {
//     font-weight: 800;
//     color: $mediumBlue;
//     letter-spacing: 0.3px;
//     font-size: 30px;
//     margin: 0;
//   }

//   form {
//     width: 100%;
//     max-width: 420px;
//     margin: auto auto 0;
//   }

//   .form-group {
//     position: relative;
//     margin-bottom: 20px;

//     i,
//     .countryCode {
//       color: #b2bfc5;
//       top: 17px;
//       position: absolute;
//       left: 15px;
//     }

//     .countryCode {
//       font-size: 16px;
//       top: 11px;
//     }

//     // .form-control {
//     //   font-weight: 600;
//     //   color: $deepBlue;
//     //   letter-spacing: 0.3px;
//     //   padding-left: 42px !important;
//     //   border-color: $borderColor;
//     //   height: 48px;

//     //   &:focus {
//     //     border-color: $themeColor;
//     //   }
//     // }

//     .eyeIcon {
//       left: unset;
//       top: 15px;
//       right: 15px;
//       position: absolute;

//       i {
//         position: static;
//         cursor: pointer;
//       }
//     }
//   }

//   // .passwordField {
//   //   .form-control {
//   //     padding-right: 38px !important;
//   //   }
//   // }

//   p {
//     margin-top: 8px;
//     color: $textColor;
//     letter-spacing: 0.3px;
//     margin-bottom: auto;

//     a {
//       font-weight: 600;
//     }
//   }

//   a {
//     text-decoration: none;
//     color: $textColor !important;
//   }

//   // .custom-control-inline {
//   //   margin-right: 1.3rem;

//   //   &:last-child {
//   //     margin-right: 0;
//   //   }

//   //   .custom-control-label {
//   //     cursor: pointer;
//   //   }
//   // }

//   // .signUpForm,
//   // .accountType {
//   //   max-width: 750px;
//   //   margin-top: 0;
//   // }

//   // .signUpForm {
//   //   margin-top: 20px;
//   // }

//   // .accountType {
//   //   width: 100%;
//   //   margin-top: auto;
//   // }

//   // .themeBtn {
//   //   width: 100%;
//   //   max-width: 350px;
//   //   margin: 25px auto 15px;
//   //   display: block;
//   // }

//   // &.signUpFormWrap {
//   //   overflow-y: auto;
//   //   justify-content: flex-start;
//   // }
// }

// .filterHead {
//   display: flex;
//   align-items: flex-end;
//   margin-top: 25px;
//   margin-bottom: 25px;

//   .customCol {
//     padding-right: 5px;
//     padding-left: 5px;
//   }

//   .customCol:first-child {
//     padding-left: 15px;
//   }

//   .customCol:last-child {
//     padding-right: 15px;
//   }
// }

// .calendarIcon {
//   position: absolute;
//   color: #adc2cc;
//   font-size: 16px;
//   bottom: 15.5px;
//   right: 12px;
// }

// .filterBody {
//   padding: 0;
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-start;
//   margin-top: -5px;
//   margin-bottom: 25px;
// }

// .filterHead,
// .filterBody,
// .filterWrapMob {
//   .form-control {
//     border-color: $blueBorderColor;
//     height: 44px;
//     color: $textColor;
//   }

//   .input-group-append .btn {
//     background: $white !important;
//     color: #adc2cc !important;
//     opacity: 1;
//     border: 1px solid $blueBorderColor;
//     border-left: 0;
//     padding-left: 8px;
//     font-size: 15px;
//     border-top-right-radius: 10px !important;
//     border-bottom-right-radius: 10px !important;
//   }

//   label {
//     padding-left: 5px;
//   }

//   span {
//     margin-left: auto;
//     color: $textColor;
//     font-size: 14px;
//     cursor: pointer;
//   }
// }

// .react-select .react-select__control .react-select__indicators {
//   width: 20px;
//   padding-right: 6px;
// }

// .react-select.primary .react-select__control.react-select__control--is-focused,
// .react-select.primary
//   .react-select__control.react-select__control--is-focused:hover {
//   border-color: #9a9a9a;
// }

// .react-select .react-select__control .react-select__placeholder,
// .react-select__menu {
//   font-weight: 400;
//   color: $grey;
//   letter-spacing: 0.3px;
//   font-size: 14px;
// }

// .react-select__menu {
//   height: unset !important;
//   max-height: unset !important;
// }

// .sidebar {
//   &:after {
//     background-image: none;
//     background-color: #123f5f;
//   }

//   .nav li>a,
//   .logo .simple-text,
//   .user a {
//     color: $white !important;
//   }

//   .nav li {
//     a {
//       margin-left: 0;
//       opacity: 1;
//       padding-left: 17px;
//       margin-top: 5px;
//       margin-right: 10px;

//       i {
//         color: $white !important;
//       }
//     }
//   }

//   .nav p {
//     font-weight: 600;
//     text-transform: capitalize;
//     font-size: 14px;
//   }

//   .active {
//     >a {
//       background-color: $themeColor;
//       border-top-right-radius: 25px;
//       border-bottom-right-radius: 25px;
//     }
//   }

//   li.active {
//     font-weight: 800;

//     .collapse.show {
//       li .customSidebarList {
//         font-weight: 400;
//       }

//       li.active .customSidebarList {
//         font-weight: 800;
//       }
//     }
//   }
// }

// .sidebar .nav li .nav>li>a {
//   padding-left: 16px;

//   i {
//     font-size: 15px !important;
//     line-height: 31px !important;
//   }

//   p {
//     font-size: 13px;
//   }
// }

// .sidebar .sidebar-wrapper li.active>a {

//   &:not([data-toggle="collapse"]):before,
//   &:not([data-toggle="collapse"]):after {
//     display: none;
//   }
// }

// .authPgFooter {
//   padding: 12px 0;
// }

// .card {
//   .card-header {
//     border-bottom: 1px solid rgba(0, 0, 0, 0.07);
//     padding-bottom: 3px;
//     margin-bottom: 3px;

//     .card-title {
//       margin-top: 2px;
//       font-weight: 400;
//       color: $grey;
//       font-size: 18px;
//       text-transform: capitalize;

//       .react-select {
//         min-width: 110px;
//       }
//     }

//     .card-subtitle {
//       text-transform: unset;
//       font-weight: 400;
//       margin-top: -5px;
//       letter-spacing: 0.3px;
//       line-height: 1.5;
//     }
//   }
// }

// .custom-file {
//   outline: 0 !important;
//   height: auto;

//   .custom-file-label {
//     height: unset;
//     padding: 9px;
//     font-size: 14px;
//     box-shadow: none !important;
//     outline: 0 !important;

//     &:after {
//       height: unset;
//       padding: 9px 10px;
//     }
//   }

//   .custom-file-input {
//     padding: 9px;
//     outline: 0 !important;
//     box-shadow: none !important;
//   }

//   .custom-file-input:focus ~ .custom-file-label {
//     border-color: #9a9a9a;
//   }
// }

// .btn-link:hover,
// .btn-link:focus,
// .btn-link:active,
// .btn-link:active:focus {
//   background: transparent !important;
//   color: $themeColor !important;
// }

// .btn-link {
//   color: #6c757d !important;
//   padding: 0;
//   font-weight: 400;
// }

// .text-primary {
//   color: $themeColor !important;
// }

// .btn i {
//   margin-right: 0;
// }

// .jobDetails,
// .notaryDetails {
//   margin-bottom: 25px;
//   background-color: $white;
//   border-radius: 10px;

//   li {
//     border-color: $blueBorderColor;
//     padding: 12px;
//     font-size: 14px;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     margin-bottom: 0;
//     color: $mediumBlue;
//     font-weight: 700;

//     label {
//       display: block;
//       font-weight: 400;
//       margin-bottom: 3px;
//     }

//     span {
//       color: $mediumBlue;
//     }
//   }
// }

.deleteNotary {
  padding: 15px;
  font-size: 16px;
  margin-top: 15px;
  color: $errorColor !important;
  text-decoration: none !important;

  i {
    font-size: 20px;
    color: $errorColor;
  }
}

.deleteLicense {
  margin: 20px auto !important;
  max-width: max-content;
  display: inline-block;
  text-decoration: none !important;

  &:hover {
    color: $white !important;
  }
}

.notaryLicenseRow {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba($black, 0.2);
}

.notaryDetails {
  margin-bottom: 0;

  li,
  li span {
    color: $deepBlue;
  }

  li span i {
    color: #66615b;
  }

  li:last-child {
    border-bottom: 0;
  }
}

// .pagination {
//   .page-item.active>.page-link {
//     background-color: $themeColor !important;
//     border-color: $themeColor !important;
//     box-shadow: none;
//     border-radius: 50% !important;
//     color: $white !important;
//     padding: 0 2px;
//   }

//   li {
//     padding: 0 !important;
//     border-bottom: 0 !important;
//   }
// }

// .customList {
//   .pagination {
//     margin: 0 auto;

//     li {
//       padding: 10px 0 !important;
//     }
//   }
// }

// .searchTable {
//   flex: 1;
//   margin-right: 10px;
//   margin-bottom: 0;

//   .btn {
//     border-radius: 6px;
//     padding: 0 10px;
//   }
// }

// .uploadDocumentInputFile {
//   position: absolute;
//   opacity: 0;
//   width: 100px;
//   right: 12px;
//   top: 13px;
//   cursor: pointer;
// }

// .createClosingUploadDocumentInputFile {
//   position: absolute;
//   opacity: 0;
//   width: 100%;
//   right: 0;
//   top: 0;
// }

// .cursorPointer {
//   cursor: pointer;
// }

// .text-underline {
//   text-decoration: underline;
// }

// .customWidth {
//   min-width: 115px;
// }

// .customWidthSelect {
//   min-width: 90px;
// }

// .addBtn {
//   height: 38px;
//   padding: 0 22px;
//   background-color: $mediumBlue;
//   color: $white;
//   margin: 0;

//   &:hover,
//   &:focus,
//   &:active,
//   &:active,
//   &:active:focus {
//     background-color: $mediumBlue !important;
//   }
// }

// .checkBoxButtonOutline,
// .checkBoxButtonSelected {
//   height: 36px;
//   padding: 0 5px;
//   width: 85px;
//   font-size: 14px;
//   border: 1px solid $borderColor;
//   background-color: $white;
//   color: $mediumBlue;
//   letter-spacing: 0.3px;
//   margin: 10px 15px 10px 0 !important;
//   font-weight: 500;

//   &:active:focus,
//   &:active,
//   &:focus {
//     background-color: $white !important;
//     color: $mediumBlue !important;
//   }

//   &:hover {
//     background-color: $mediumBlue !important;
//     color: $white !important;
//   }
// }

// .checkBoxButtonSelected {
//   border: 1px solid $mediumBlue;
//   background-color: $mediumBlue;
//   color: $white;

//   &:hover,
//   &:focus,
//   &:active,
//   &:active,
//   &:active:focus {
//     background-color: $mediumBlue !important;
//     color: $white !important;
//   }
// }

// .notificationsWrap {
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
//   box-shadow: none;
//   color: $textColor;

//   // .card-body {
//   //   padding: 6px 0;
//   // }

//   li {
//     padding: 15px;
//     font-size: 14px;
//     letter-spacing: 0.3px;
//     border: 0;
//     border-bottom: 1px solid $blueBorderColor;

//     &:last-child {
//       border-bottom: 0;
//     }

//     span {
//       color: $mediumBlue;
//       font-weight: 600;
//       font-size: 13px;
//       display: inline-block;
//       margin-bottom: 4px;
//     }

//     div {
//       cursor: pointer;
//     }
//   }
// }

// .someTipsFaq {
//   font-size: 16px;
//   margin-bottom: 0;
//   line-height: 1.4;
//   color: $textColor;

//   a {
//     display: inline-block;
//     margin-left: 3px;
//     font-weight: 600;
//     text-decoration: underline;
//   }
// }

// .searchBarFaqWrap {
//   margin-top: 20px;
//   position: relative;

//   input {
//     height: 50px;
//     border: 1px solid $blueBorderColor;
//     letter-spacing: 0.3px;
//     padding: 6px 48px 6px 37px !important;
//   }

//   .clearBtn {
//     position: absolute;
//     background: transparent;
//     padding: 0 6px;
//     top: -1px;
//     left: 4px;
//     border-radius: 4px;
//     border: 0;
//     z-index: 99;
//     font-size: 18px;
//     color: #bbb;
//     height: 34px;
//     line-height: 34px;

//     &:hover,
//     &:active,
//     &:focus,
//     &:active:focus,
//     &:active:hover {
//       color: #bbb !important;
//       background: transparent !important;
//     }
//   }

//   .searchBtnFaq {
//     position: absolute;
//     top: 8px;
//     margin: 0;
//     right: 9px;
//     padding: 0 10px;
//     background-color: $themeColor;
//     color: $white;
//     border-color: $themeColor;
//     height: 34px;
//     border-radius: 6px;
//     line-height: 34px;

//     &:hover,
//     &:active,
//     &:focus,
//     &:active:focus,
//     &:active:hover {
//       color: $white !important;
//       background: $themeColor !important;
//     }
//   }
// }

// .faqSectionHeader {
//   margin-bottom: 12px;
//   font-size: 18px;
//   padding-left: 1px;
//   font-weight: 700;
//   color: $deepBlue;
// }

// .faqSection {
//   margin-top: 25px;

//   .card {
//     border-radius: 10px !important;
//     margin-bottom: 10px;
//     box-shadow: none;
//     border: 1px solid $blueBorderColor;

//     .card-body {
//       border-top: 1px solid $blueBorderColor;
//       padding: 18px 14px 10px;
//       font-size: 14px;
//       letter-spacing: 0.3px;
//       color: #666;

//       p {
//         margin-bottom: 10px;
//       }
//     }

//     .card-header {
//       padding: 14px;
//       font-size: 14px;
//       letter-spacing: 0.3px;
//       border-bottom: 0;
//       margin-bottom: 0;
//       cursor: pointer;
//       color: $deepBlue;
//     }
//   }
// }

// .contactWrap {
//   box-shadow: none;
//   border: 1px solid $blueBorderColor;

//   form {
//     width: 720px;
//     max-width: 100%;
//   }

//   p {
//     font-size: 16px;
//     color: $mediumBlue;
//     margin-top: 8px;
//     margin-bottom: 25px;
//   }

//   .btn {
//     display: block;
//     margin: 30px auto 15px;
//   }

//   select {
//     height: 48px;
//   }
// }

// .card-calendar {
//   margin-top: 18px;
//   box-shadow: none;
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
// }

// .btn {
//   &-link {
//     &.remove {
//       color: $errorColor !important;
//     }
//   }
// }

// calendar css
// .rbc-toolbar {
//   padding: 5px 5px 30px;
//   margin-bottom: 0;
//   display: flex;
//   justify-content: space-between;
//   font-size: 14px;

//   .rbc-toolbar-label {
//     font-size: 24px;
//     font-weight: 700;
//     color: $mediumBlue;
//   }

//   button {
//     background-color: $white;
//     border: 1px solid $mediumBlue;
//     color: $mediumBlue;
//     margin: 0 3px !important;
//     font-weight: 400;
//     padding: 0 6px;
//     height: 32px;
//     line-height: 30px;
//     border-radius: 6px !important;
//     font-size: 13px;
//     font-weight: 500;
//     font-family: "Mulish", sans-serif;

//     &:hover,
//     &:focus,
//     &:active,
//     &:active,
//     &:active:focus {
//       background-color: $mediumBlue !important;
//       color: $white !important;
//     }
//   }

//   .rbc-active {
//     background-color: $mediumBlue !important;
//     color: $white !important;
//     font-weight: 600;
//   }
// }

// .rbc-today {
//   background-color: #f9f9f9;
// }

// .rbc-calendar {
//   margin-bottom: 15px;
// }

// .rbc-header>a {
//   color: $mediumBlue !important;
// }

// .sweet-alert .btn {
//   height: 36px;
//   line-height: 36px;
//   padding: 0 22px;
//   margin-bottom: 0;
//   color: $white !important;
// }

// .sweet-alert h2 {
//   font-size: 24px !important;
//   margin-bottom: 20px !important;
// }

// .calendarCheckbox {
//   display: flex;
//   align-items: center;
//   margin-top: 8px;
//   margin-bottom: 10px;
//   padding: 0 10px;

//   .calendarAppointment {
//     margin-right: 25px;
//   }

//   .calendarAppointment .form-check-sign::before,
//   .calendarAppointment .form-check-sign::after {
//     background-color: #ff7300;
//   }

//   .calendarAppointment
//     .form-check-label
//     input[type="checkbox"]:checked
//     + .form-check-sign:before {
//     background-color: #ff7300;
//   }

//   .calendarReceivedLegend .form-check-sign::before,
//   .calendarReceivedLegend .form-check-sign::after {
//     background-color: #a5d6a7;
//   }

//   .calendarReceivedLegend
//     .form-check-label
//     input[type="checkbox"]:checked
//     + .form-check-sign:before {
//     background-color: #a5d6a7;
//   }

//   .form-check .form-check-label {
//     font-size: 14px;
//     color: $textColor;
//   }
// }

.rbc-event.event-azure {
  background-color: rgba(81, 188, 218, 0.3);
}

.rbc-event.event-orange {
  background-color: rgba(251, 198, 88, 0.25);
}

.rbc-day-slot .rbc-event-label {
  color: #19375b;
  padding: 0 5px;
  margin-bottom: 1px;
}

.rbc-event .rbc-event-content:focus {
  outline: 0;
}

// .deadline {
//   background-color: $mediumBlue;
//   text-align: center;
//   color: $white;
//   padding: 1px 3px 2px;
//   width: 65px;
//   border-radius: 4px;
//   letter-spacing: 0.5px;

//   h4 {
//     font-size: 22px;
//     margin: 0 0 -3px;
//     font-weight: 600;
//   }

//   p {
//     font-size: 14px;
//     margin-bottom: 0;
//     font-weight: 600;
//     text-transform: uppercase;
//   }
// }

// .documentTableWrap {
//   // height: 355px;
//   overflow: auto;
// }

.rating-stars {
  display: flex;
  justify-content: center;

  .btn {
    font-size: 30px;
    margin: 0 4px;
    padding: 0 3px;
  }

  .fa-star-o {
    color: #999;
  }

  .fa-star {
    color: #f6ab28;
  }
}

// .sidebar .nav i {
//   font-size: 18px;
//   margin-right: 5px;
//   width: 24px;
//   line-height: 28px;
// }

// .sidebar .user .photo {
//   margin-left: 20px;

//   img {
//     height: 100%;
//     object-fit: cover;
//   }
// }

// .react-select.primary
//   .react-select__control
//   .react-select__value-container--has-value
//   .react-select__single-value {
//   color: $grey;
// }

// .react-select__menu-list {
//   padding: 0;
// }

// .orderDetailsStatus {
//   width: 120px;
// }

// .transactionSummary {
//   padding: 15px;
//   background-color: $white;
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
//   margin-top: 25px;
//   margin-bottom: 25px;
//   text-align: center;
//   width: 100%;

//   div:not(:first-child) {
//     border-left: 1px solid $blueBorderColor;
//   }

//   h6 {
//     margin-bottom: 12px;
//     text-transform: capitalize;
//     color: $textColor;
//     font-weight: 400;
//     font-size: 14px;
//   }

//   p {
//     font-size: 18px;
//     margin-bottom: 0;
//     color: $mediumBlue;
//     font-weight: 700;
//   }

//   .btn {
//     height: 26px;
//     border-radius: 4px;
//     line-height: 26px;
//     margin-top: 2px;
//     margin-bottom: 0;
//     font-size: 16px;
//     padding: 0 10px;
//     border: 1px solid $themeColor;
//     background-color: $white;
//     color: $themeColor;

//     &:hover,
//     &:active,
//     &:focus,
//     &:active:focus,
//     &:active:hover {
//       background-color: $themeColor !important;
//     }
//   }
// }

// .planBenefits {
//   margin-top: 20px;
//   margin-bottom: 5px;

//   li {
//     border: 0;
//     text-align: center;
//     font-size: 14px;
//     padding: 5px 0 5px 22px;
//     padding: 5px 0;
//     color: $mediumBlue;
//     position: relative;

//     &:before {
//       content: "\f00c";
//       font-family: "fontAwesome";
//       font-size: 14px;
//       color: #63bb43;
//       position: absolute;
//       left: 0;
//       top: 5px;
//     }

//     p {
//       color: $mediumBlue !important;
//     }
//   }
// }

.customSwitch {
  .bootstrap-switch {
    margin-bottom: 0;
  }

  .bootstrap-switch.bootstrap-switch-off .bootstrap-switch-primary {
    background-color: $mediumBlue;
  }

  .bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary {
    background-color: #218838;
  }
}

// .upgradeBtn,
// .downgradeBtn {
//   border: 1px solid $themeColor;
//   background-color: $themeColor;
//   color: $white;
//   height: 34px;
//   line-height: 32px;
//   margin: 0 auto;
//   // margin-left: auto;
//   border-radius: 6px;
//   text-transform: capitalize;
//   padding: 0 10px;

//   &:hover,
//   &:focus,
//   &:active,
//   &:active,
//   &:active:focus {
//     background-color: $themeColor !important;
//     border-color: $themeColor !important;
//     color: $white !important;
//   }
// }

// .downgradeBtn {
//   background-color: $white;
//   color: $themeColor;

//   &:hover,
//   &:focus,
//   &:active,
//   &:active,
//   &:active:focus {
//     background-color: $white !important;
//     color: $themeColor !important;
//   }
// }

// .copyTrackingNum {
//   display: flex;
//   align-items: center;

//   span {
//     cursor: pointer;
//   }

//   .btn {
//     height: 28px;
//     margin: 0;
//     padding: 0 12px;
//     display: none;
//   }

//   &:hover {
//     .btn {
//       display: initial;
//     }
//   }
// }

// .orTxtWrap {
//   height: 1px;
//   background: rgba($black, 0.06);
//   position: relative;
//   z-index: 0;
//   margin: 15px auto 32px;
//   width: 325px;

//   p {
//     position: absolute;
//     background: #fbfbfb;
//     border-radius: 6px;
//     left: 50%;
//     right: 50%;
//     transform: translate(-50%, -50%);
//     font-size: 14px;
//     margin-bottom: 0;
//     z-index: 99;
//     width: 38px;
//     height: 26px;
//     line-height: 26px;
//     text-align: center;
//     box-shadow: 0 0.125rem 0.25rem rgba($black, 0.15) !important;
//   }
// }

// .uploadReceipt {
//   background: $white !important;
//   border: 1px solid rgba($black, 0.1);
//   padding: 2px;
//   cursor: pointer;
//   width: 75px;
//   height: 75px;
//   border-radius: 6px;
//   display: block;
//   margin: 0 auto !important;
//   position: relative;

//   img {
//     height: 69px;
//     width: 69px;
//     border-radius: 4px;
//     object-fit: cover;
//   }

//   .btn {
//     position: absolute;
//     height: 20px;
//     width: 20px;
//     padding: 0;
//     text-align: center;
//     line-height: 20px;
//     font-size: 14px;
//     color: $errorColor;
//     border-radius: 50%;
//     top: -16px;
//     right: -8px;
//     background: $white !important;
//     box-shadow: 0 0.125rem 0.25rem rgba($black, 0.15) !important;

//     &:hover,
//     &:active,
//     &:focus,
//     &:active:focus,
//     &:active:hover {
//       background-color: $white !important;
//       color: $errorColor !important;
//     }
//   }
// }

// .btn-link {
//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: transparent !important;
//   }
// }

// .sidebar .logo {
//   .simple-text {
//     span {
//       font-size: 20px;
//       font-weight: 800;
//     }

//     .logo-img {
//       border-radius: 8px;

//       img {
//         width: 72%;
//         height: 72%;
//         border-radius: 5px;
//       }
//     }
//   }
// }

// .sidebar .sidebar-wrapper {
//   overflow-x: hidden;
//   scrollbar-color: #e0e0e0 #f5f5f5;
//   scrollbar-width: thin;
//   height: calc(100vh - 125px);

//   &::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
//     box-shadow: inset 0 0 6px rgba($black, 0.1);
//     border-radius: 0;
//     background-color: #f5f5f5;
//   }

//   &::-webkit-scrollbar {
//     width: 5px;
//     background-color: #f5f5f5;
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: 0;
//     -webkit-box-shadow: inset 0 0 6px rgba($black, 0.18);
//     box-shadow: inset 0 0 6px rgba($black, 0.1);
//     background-color: #e0e0e0;
//   }
// }

// .dateRange .react-daterange-picker__wrapper {
//   color: $textColor;
//   font-size: 14px;
//   padding: 4px;
//   height: 46px;
//   background-color: $white;
//   background-clip: padding-box;
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
// }

// .dateRange.react-daterange-picker--open .react-daterange-picker__wrapper {
//   border: 1px solid $blueBorderColor;
// }

// .dateRange .react-daterange-picker__inputGroup,
// .dateRange .react-daterange-picker__inputGroup input {
//   color: $textColor;
//   outline: 0 !important;
// }

// .react-daterange-picker__range-divider {
//   margin-right: 3px;
//   margin-top: 0;
//   margin-left: 3px;
// }

// .dateRange .react-daterange-picker__button {
//   outline: 0 !important;
//   padding: 2px 3px 3px;
// }

.react-calendar__tile--active {
  background: $mediumBlue !important;
}

.react-calendar__navigation {
  background: $mediumBlue;
  margin-bottom: 0 !important;
}

// .react-daterange-picker {
//   width: 100%;
// }

// .react-daterange-picker__calendar {
//   margin: 5px auto;
//   box-shadow: 0 1px 4px 0 rgba($black, 0.1);
//   border: 1px solid #f5f5f5;
//   width: 310px !important;
//   z-index: 99;
// }

// .react-calendar__month-view__weekdays {
//   background: $mediumBlue;
//   color: $white;
// }

// .react-calendar__navigation button {
//   color: $white;
//   font-size: 18px;
// }

// .react-calendar__navigation button:enabled:focus,
// .react-calendar__navigation button:enabled:hover {
//   background-color: $mediumBlue;
// }

// .filterHead .dateRange span.react-calendar__navigation__label__labelText,
// .filterWrapMob .dateRange span.react-calendar__navigation__label__labelText {
//   font-size: 15px !important;
//   font-family: "Mulish", sans-serif;
//   color: $white !important;
//   font-weight: 700;
// }

.react-calendar__month-view__weekdays__weekday {
  font-size: 13px;
  // font-family: "Mulish", sans-serif;
  font-weight: unset;
}

// .react-calendar__month-view__days__day {
//   font-family: "Mulish", sans-serif;
// }

.react-calendar {
  border: 0 !important;
}

// .react-daterange-picker__inputGroup__input:invalid {
//   background-color: $white;
// }

// .react-daterange-picker__calendar-button {
//   display: none;
// }

.react-calendar__tile--now {
  background-color: $mediumBlue !important;
  color: $white;
  font-weight: 600;
}

// .rdtCounters .rdtCounter:last-child .rdtCount {
//   color: $mediumBlue;
//   border: 1px solid $mediumBlue;
// }

// .sidebar .sidebar-wrapper>.nav [data-toggle="collapse"]~div>ul>li>a i {
//   line-height: 28px;
// }

// .uploadFileCustom {
//   .custom-file-input,
//   .custom-file-label,
//   .custom-file-label:after {
//     padding: 6px;
//   }
// }

// .sidebar .nav .caret {
//   top: unset;
//   position: unset;
//   margin-right: 5px;
// }

// .plTotal,
// .netIncome {
//   font-size: 18px;
//   margin-bottom: 0;
//   font-weight: 700;
//   letter-spacing: 0.3px;
//   color: $deepBlue;
// }

// .netIncome {
//   font-size: 20px;
// }

// .textUnderline {
//   text-decoration: underline;
// }

// .toast {
//   color: $white !important;
//   font-family: "Mulish", sans-serif;
//   padding: 5px 12px !important;
//   font-size: 14px !important;
//   border-radius: 4px !important;
//   letter-spacing: 0.3px;
//   flex-basis: 0 !important;
// }

// .deletePreview {
//   position: absolute;
//   top: -16px;
//   right: -8px;
//   width: 18px;
//   height: 18px;
//   padding: 0;
//   font-size: 14px;
//   line-height: 18px;
//   border-radius: 50%;
//   color: #ff2323;
//   background-color: $white;
//   border: 0;
//   text-align: center;
//   box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     color: #ff2323 !important;
//     background-color: $white !important;
//     box-shadow: 0px 1px 5px 0px rgba($black, 0.22) !important;
//   }
// }

.msgPreview {
  padding: 5px 6px;
  border: 1px solid $blueBorderColor;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: $white;
  font-size: 14px;
  margin-left: 15px;
  flex-shrink: 0;
  width: max-content;
  padding-inline: 2px;

  img {
    height: 36px;
    margin-right: 5px;
    width: 36px;
    object-fit: cover;
  }

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// .uploadFilesWrap {
//   overflow-x: auto;
//   display: flex;
//   flex: 1;
// }

// .newLeaveDateRange {
//   width: unset;

//   .react-daterange-picker__wrapper {
//     width: 100%;
//   }
// }

// react table
// .react-bs-table-bordered,
// .statusReportTable {
//   border-color: #ddd;
//   border-radius: 10px;
//   background-color: $white;

//   thead > tr > th {
//     text-transform: capitalize;
//     padding: 19px 7px 17px;
//     color: $deepBlue;
//     font-weight: 700;
//   }
// }

// .adminTable {
//   .table-bordered {
//     tbody {
//       .form-control {
//         border-radius: 6px;
//         border-color: $blueBorderColor;
//         font-size: 14px;
//         color: $textColor;
//         padding: 4px 6px !important;
//         letter-spacing: unset;
//       }

//       .btn-link {
//         color: #999;
//       }

//       .actionBtn {
//         color: #999;
//         padding: 0 8px;
//         font-size: 15px;
//       }

//       .showMap {
//         font-size: 14px;
//         color: $themeColor !important;
//         text-decoration: underline !important;
//         font-weight: 400;
//         margin-top: -2px;
//       }

//       .markPaid {
//         background-color: $white;
//         border: 1px solid $mediumBlue;
//         color: $mediumBlue;
//         font-weight: 700;
//         margin: 0;
//         height: 34px;
//         line-height: 32px;
//         padding: 0 6px;

//         &:hover,
//         &:active,
//         &:focus,
//         &:active:focus,
//         &:active:hover {
//           background-color: $white !important;
//           color: $mediumBlue !important;
//         }
//       }

//       td {
//         border-color: $blueBorderColor;
//         color: $textColor;
//         font-size: 14px;

//         &:nth-child(2) {
//           border-left: 0;
//         }
//       }

//       tr {
//         &:last-child td {
//           border-bottom: 0;
//         }
//       }
//     }

//     td {
//       border: 0;
//       border-top: 1px solid $blueBorderColor;
//     }
//   }
// }

// .form-check-radio .form-check-sign::before,
// .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
//   top: -6px;
// }

// .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
//   color: $mediumBlue;
// }

// .statusSuccess,
// .statusPending,
// .statusCancelled {
//   height: 26px;
//   line-height: 26px;
//   display: inline-block;
//   padding: 0 7px;
//   border-radius: 6px;
//   font-size: 14px;
//   font-weight: 500;
// }

// .statusPending {
//   background-color: #fce8ce;
//   color: #f6920f;
// }

// .statusSuccess {
//   background-color: #cafab6;
//   color: #10ac1f;
// }

// .statusCancelled {
//   background-color: #ff7474;
//   color: #ff3333;
// }

// .form-check input[type="checkbox"]:checked + .form-check-sign::after {
//   background-color: $mediumBlue;
// }

// .tableTotalWrap {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border: 1px solid $blueBorderColor;
//   background-color: $white;
//   border-radius: 10px;
//   padding: 20px 15px;
//   color: $mediumBlue;
//   font-weight: 700;
//   font-size: 18px;

//   p {
//     margin-bottom: 0;
//   }
// }

// .subscriptionPlanWrap {
//   border-radius: 10px;
//   box-shadow: none;
//   border: 1px solid $blueBorderColor;
//   margin-bottom: 25px;

//   .card-header {
//     border-color: $blueBorderColor;
//     padding: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .card-title {
//       color: $deepBlue;
//       font-weight: 600;
//       margin-bottom: 0;
//       margin-top: 0;
//     }
//   }

//   .card-body {
//     // padding: 13px 12px 10px;

//     h5 {
//       color: $deepBlue;
//       font-weight: 700;
//     }

//     p {
//       color: $textColor;
//     }
//   }
// }

// .cancelBtn {
//   border-width: 1px !important;
//   height: 34px;
//   line-height: 32px;
//   margin: 0;
// }

// .ribbon {
//   width: 130px;
//   height: 130px;
//   overflow: hidden;
//   position: absolute;
// }

// .ribbon::before,
// .ribbon::after {
//   position: absolute;
//   z-index: -1;
//   content: "";
//   display: block;
//   border: 5px solid #31b27c;
//   z-index: 9;
// }

// .ribbon span {
//   position: absolute;
//   display: block;
//   width: 235px;
//   padding: 15px 0;
//   background-color: #31b27c;
//   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
//   color: #fff;
//   font-size: 13px;
//   font-weight: 600;
//   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
//   text-transform: uppercase;
//   text-align: center;
// }

/* top right*/
// .ribbon-top-right {
//   top: -10px;
//   right: -10px;
// }

// .ribbon-top-right::before,
// .ribbon-top-right::after {
//   border-top-color: transparent;
//   border-right-color: transparent;
// }

// .ribbon-top-right::before {
//   top: -1px;
//   left: 6px;
// }

// .ribbon-top-right::after {
//   bottom: 6px;
//   right: -1px;
// }

// .ribbon-top-right span {
//   left: -33px;
//   top: 18px;
//   transform: rotate(45deg);
// }

// .normalTableWrap {
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
//   box-shadow: none;

//   .card-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 15px;
//     border-bottom: 1px solid $blueBorderColor;
//     margin-bottom: 0;

//     .card-title {
//       margin-top: 0;
//       margin-bottom: 0;
//       color: $mediumBlue;
//       font-weight: 700;
//     }

//     .btn {
//       background-color: $mediumBlue;
//       border-color: $mediumBlue;
//       border-radius: 6px;
//       height: 34px;
//       line-height: 32px;
//       font-weight: 700;
//       color: $white;
//       padding: 0 16px;
//       margin-top: 0;
//       margin-bottom: 0;
//       cursor: pointer;

//       &:hover,
//       &:active,
//       &:focus,
//       &:active:focus,
//       &:active:hover {
//         background-color: $mediumBlue !important;
//         border-color: $mediumBlue !important;
//       }
//     }

//     .themeBtnOutline {
//       margin-right: 10px;

//       &:hover,
//       &:active,
//       &:focus,
//       &:active:focus,
//       &:active:hover {
//         background-color: $white !important;
//         color: $themeColor !important;
//       }
//     }

//     .css-yk16xz-control,
//     .css-2b097c-container {
//       width: 200px;
//     }

//     .css-yk16xz-control {
//       margin-left: 8px;
//     }
//   }

//   // .card-body {
//   //   padding: 0;
//   // }

//   // .table-responsive {
//   //   padding-bottom: 0;
//   // }

//   // table {
//   //   margin-bottom: 0;

//   //   .form-control {
//   //     border-radius: 6px;
//   //     border-color: $blueBorderColor;
//   //     font-size: 14px;
//   //     color: $textColor;
//   //     padding: 4px 6px !important;
//   //     letter-spacing: unset;
//   //   }

//   //   .actionBtn {
//   //     color: #999;
//   //     padding: 0 8px;
//   //     font-size: 16px;
//   //   }

//   //   .showMap {
//   //     font-size: 14px;
//   //     color: $themeColor !important;
//   //     text-decoration: underline !important;
//   //     font-weight: 400;
//   //     margin-top: -2px;
//   //   }

//   //   thead > tr > th {
//   //     text-transform: capitalize;
//   //     padding: 19px 7px 17px;
//   //     color: $deepBlue;
//   //     font-weight: 700;
//   //   }

//   //   td {
//   //     border-color: $blueBorderColor;
//   //     color: $textColor;
//   //     font-size: 14px;
//   //   }

//   //   tbody {
//   //     tr td {
//   //       vertical-align: top;
//   //       padding: 15px 7px;

//   //       button {
//   //         &.confirmBtn {
//   //           &:hover {
//   //             background-color: #e98057 !important;
//   //           }
//   //         }
//   //       }
//   //     }
//   //   }
//   // }

//   .react-bs-table-bordered {
//     border: 0;
//   }

//   .react-bs-table-pagination {
//     padding: 0 12px;
//   }

//   .notaryDetails {
//     li {
//       border: 0;
//       padding: 15px;

//       &:not(:last-child) {
//         border-bottom: 1px solid $blueBorderColor;
//       }
//     }
//   }

//   .addedBy {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     label,
//     p {
//       margin-bottom: 0;
//     }
//   }
// }

// .profileInfo,
// .modal-body {
// border: 1px solid $blueBorderColor;
// border-radius: 10px;
// box-shadow: none;

// input,
// select {
//   height: 48px;
// }

// textarea {
//   border-radius: 10px;
// }

// .input-group-text {
//   background-color: #f6f7fc;
//   border-color: $borderColor;
//   width: 46px;
//   border-top-left-radius: 10px;
//   border-bottom-left-radius: 10px;
//   padding-left: 0 !important;
//   text-align: center;
//   display: inline-block;
// }
// }

// .profileInfo {
//   .card-header .card-title {
//     color: $mediumBlue;
//     font-weight: 600;
//   }
// }

// .detailsPgCard {
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
//   box-shadow: none;

//   .card-header {
//     border-color: $blueBorderColor;

//     .card-title {
//       color: $mediumBlue;
//       font-weight: 700;
//     }
//   }

//   h6 {
//     color: $mediumBlue;
//     margin-top: 20px;
//     font-weight: 700;
//   }

//   p {
//     color: $textColor;
//     margin-bottom: 20px;
//     font-size: 15px;
//   }

//   .customLeftBorder {
//     &:not(:first-child) {
//       border-left: 1px solid $blueBorderColor;
//     }
//   }

//   .card-text {
//     font-size: 17px;
//     margin-bottom: 0;
//     font-weight: 600;
//   }

//   .customBottomBorder {
//     &:not(:last-child) {
//       padding-bottom: 20px;
//       border-bottom: 1px solid $blueBorderColor;
//       margin-bottom: 20px;
//     }
//   }
// }

// .afterHrsTxt {
//   font-size: 16px;
//   font-weight: 600;
//   margin-bottom: 10px;
//   margin-top: 15px;
//   letter-spacing: 0.3px;
// }

// .modal-body {
//   padding: 10px 15px;

//   border: 0;
//   border-radius: 0;
//   box-shadow: none;

//   .dateRange .react-daterange-picker__inputGroup input {
//     height: inherit;
//   }

//   input[type="checkbox"] {
//     height: inherit;
//   }
// }

// .backBtn {
//   height: 30px;
//   font-size: 18px;
//   line-height: 34px;
//   margin: 0;
//   margin-right: 10px;
//   padding: 0 4px;
//   color: $deepBlue !important;

//   &:hover {
//     color: $deepBlue !important;
//   }
// }

// .profilePgTab {
//   .card {
//     border: 1px solid $blueBorderColor;
//     border-radius: 10px;
//     box-shadow: none;

//     .card-header {
//       border-color: $blueBorderColor;

//       .btn {
//         background-color: $themeColor;
//         border-radius: 6px;
//         height: 34px;
//         line-height: 34px;
//         font-weight: 700;
//         color: $white;
//         padding: 0 20px;
//         margin-top: 0;
//         margin-bottom: 10px;

//         &:hover,
//         &:active,
//         &:focus,
//         &:active:focus,
//         &:active:hover {
//           background-color: $themeColor !important;
//         }
//       }
//     }

//     .card-title {
//       font-weight: 700;
//       color: $deepBlue;
//       margin-top: 0;
//       margin-bottom: 10px;
//     }

//     .statusSuccess,
//     .statusPending {
//       margin-left: 10px;
//     }

//     .statusCancelled {
//       margin-right: 5px;
//     }

//     .card-subtitle {
//       color: $textColor;
//       margin-top: -2px;
//     }
//   }
// }

// .tableInsideCard {
//   .card-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 0;
//   }

//   .card-body {
//     padding: 0;
//   }

//   .react-bs-table-bordered {
//     border-radius: 0;
//     border: 0;
//     border-bottom: 1px solid $blueBorderColor;

//     thead>tr>th,
//     tbody tr td {
//       padding-left: 15px;
//       padding-right: 15px;
//     }
//   }

//   .react-bs-table-pagination {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
// }

// .workInfo {
//   h5 {
//     font-size: 18px;
//     color: $mediumBlue;
//     font-weight: 700;
//   }

//   .card-body {
//     padding-top: 20px;
//     padding-bottom: 20px;
//   }

//   img {
//     width: 24px;
//     vertical-align: -5px;
//     margin-right: 10px;
//   }
// }

// .workTimings {
//   border: 1px solid $borderColor;
//   height: 40px;
//   padding-left: 20px;
//   padding-right: 20px;
//   font-size: 14px;
//   color: $mediumBlue;
//   line-height: 38px;
//   text-align: center;
//   max-width: max-content;
//   border-radius: 10px;
//   margin-top: 20px;
// }

// .customBorderMySchedule {
//   border-right: 1px solid $blueBorderColor;
// }

// .plCardWrap {
//   box-shadow: none;
//   border: 1px solid $blueBorderColor;
//   overflow: hidden;

//   .card-header {
//     border-color: $blueBorderColor;

//     .card-title {
//       font-weight: 700;
//       font-size: 18px;
//       color: $mediumBlue;
//     }
//   }

//   .card-footer {
//     border-top: 1px solid $blueBorderColor;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 15px;
//   }

//   .card-body {
//     padding: 0;
//   }
// }

// .clientImg {
//   width: 55px;
//   height: 55px;
//   object-fit: cover;
//   border-radius: 6px;
//   margin-right: 5px;
// }

// .customFilterExpense {
//   width: 400px;
//   margin-top: 0;
//   margin-bottom: 0;
//   align-self: flex-end;
// }

// #minimizeSidebar {
//   border-radius: 8px;
//   background-color: $white;
//   border: 1px solid $mediumBlue;
//   color: $mediumBlue;
//   font-size: 16px;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $mediumBlue !important;
//     color: $white;
//   }
// }

// pre {
//   color: $textColor;
//   font-size: 14px;
//   margin-bottom: 0;
// }

// .truncateTxt {
//   overflow-x: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

// .counterOfferWrap {
//   padding: 20px 15px 22px;
//   border-radius: 15px;
//   border: 1px solid $blueBorderColor;
//   background-color: $white;
//   width: 100%;
//   max-width: 650px;
//   margin: 30px auto;
// }

// .customSearchOptions {
//   li {
//     padding: 9px 1px;
//     font-size: 13px;
//     color: $textColor;
//     border-color: $blueBorderColor;
//   }
// }

// .customTextAlignment {
//   text-align: right;
// }

// .attorneyDetailsActionArea {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;

//   .selectAgent {
//     display: flex;
//     margin-bottom: 12px;
//     align-items: center;
//   }
// }

// .closingID {
//   padding-left: 34px;
//   font-size: 18px;
//   color: $deepBlue;
//   margin-top: 12px;
// }

// .custom-switch {
//   label {
//     cursor: pointer;
//   }

// .custom-control-label::after {
//   top: 5px;
// }
// }

// .commentsMileageLog {
//   white-space: normal;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
//   /* number of lines to show */
//   -webkit-box-orient: vertical;
// }

.form-check-radio {
  .form-check-label {
    cursor: pointer;
  }
}

.customSelect {
  height: 48px;

  .css-yk16xz-control {
    height: 48px;
    border-radius: 10px;

    &:hover {
      border-color: $borderColor;
    }

    div:first-child {
      height: 48px;
      padding: 0 8px;

      div:first-child {
        position: absolute;
        top: unset;
        bottom: 0;
        transform: unset;
        height: 48px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    input {
      height: 48px;
    }

    div:first-child {
      div:first-child + div {
        margin-bottom: 0;
        height: 48px;
        margin-top: -4px;
      }
    }
  }

  .css-1pahdxg-control {
    box-shadow: none;
    border-radius: 10px;
    height: 48px;

    div:first-child {
      height: 48px;

      div:first-child {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      border-color: $borderColor;
    }

    div:first-child {
      div:first-child + div {
        margin-bottom: 0;
        height: 48px;
        margin-top: -4px;
      }
    }

    input {
      height: 48px;
    }
  }

  .css-26l3qy-menu {
    z-index: 99;
    // max-height: 200px;
    // overflow: auto;
  }
}

// .clientInvoiceEmail {
//   color: $grey;
//   letter-spacing: 0.3px;
//   height: 48px;
//   padding: 4px 8px;
//   border-radius: 10px;
//   font-size: 14px;
//   border: 1px solid $borderColor;
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
// }

// .customNavTxt {
//   display: none;
// }

// .sidebar-mini {
//   .customSidebarList {
//     span {
//       display: none !important;
//       transition: all 0.2s;
//       transition: all 300ms ease 0s;
//     }

//     img {
//       height: 26px;
//       margin-right: 0;
//       margin-left: 6px;
//     }
//   }
// }

// .sidebar:hover {
//   .customSidebarList {
//     span {
//       display: inline-block !important;
//       // transition: all 0.2s;
//       transition: all 300ms ease 0s;
//     }

//     img {
//       height: 24px;
//       margin-right: 10px;
//       margin-left: 0;
//     }

//     .caret {
//       display: initial;
//     }
//   }
// }

// .customSidebarList {
//   display: flex !important;
//   align-items: center;

//   img {
//     height: 24px;
//     margin-right: 10px;
//   }

//   span {
//     font-size: 11.5px;
//     // transition: all 0.2s;
//     transition: all 300ms ease 0s;
//     display: inline-block !important;
//     margin-right: auto;
//   }
// }

// .locationBtn {
//   white-space: normal;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
//   text-align: left;
// }

// .statusReportWrap {
//   .card-body {
//     padding: 15px 10px 20px;

//     &:last-child {
//       padding-bottom: 2px;
//     }
//   }

//   .tableHeadStatusReport {
//     padding: 12px 15px 11px;
//     display: flex;
//     justify-content: space-between;
//     background-color: #f8f9fa;
//     border-bottom: 1px solid $blueBorderColor;
//     border-top: 1px solid $blueBorderColor;

//     h6 {
//       margin-bottom: 0;
//       font-size: 15px;
//       color: $deepBlue;
//       font-weight: 500;

//       span {
//         font-weight: 700;
//       }
//     }
//   }

//   .totalFeesStatusReport {
//     padding: 15px;
//     border-bottom: 1px solid $blueBorderColor;
//     display: flex;
//     justify-content: space-between;

//     h6 {
//       font-size: 16px;
//       font-weight: 700;
//       color: $mediumBlue;
//       margin-bottom: 0;
//       text-transform: capitalize;
//     }
//   }

//   .card-body:last-child .totalFeesStatusReport {
//     border-bottom: 0;
//   }

//   .table-responsive {
//     padding-bottom: 0;
//   }
// }

// .statusReportTable {
//   border-radius: 0;
//   border: 0;
//   border-bottom: 1px solid $blueBorderColor;
//   margin-bottom: 0;

//   thead > tr > th,
//   tbody tr td {
//     padding-left: 15px;
//     padding-right: 15px;
//   }

//   tbody td {
//     border-color: $blueBorderColor;
//     color: $textColor;
//     font-size: 14px;
//   }
// }

// upload progress css
#mediaQueueViewerWrapper {
  position: fixed;
  right: 25px;
  bottom: 15px;
  z-index: 999;
  height: 50px;
  background-color: $mediumBlue;
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  #uploadeProgressHeader {
    color: $white;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
  }
}

// .customMargin {
//   display: block;
//   margin: 8px auto 22px !important;
// }

.rdtPicker {
  th.rdtSwitch,
  th.rdtPrev,
  th.rdtNext,
  .dow {
    color: $mediumBlue;
  }

  .rdtDay.rdtToday.rdtActive,
  .rdtDay.rdtActive,
  .rdtDay.rdtActive:hover {
    background-color: $mediumBlue !important;
  }
}

// .userProfilePassword {
//   position: relative;

//   .eyeIcon {
//     left: unset;
//     top: 41px;
//     right: 12px;
//     position: absolute;
//     width: 24px;
//     text-align: center;

//     i {
//       cursor: pointer;
//     }
//   }
// }

// .verifyBtn,
// .deleteBtn {
//   font-size: 14px;
//   width: 24px;
//   height: 24px;
//   padding: 0;
//   border-radius: 50%;
//   border: 1px solid #28a745 !important;
//   background-color: $white !important;
//   color: #28a745 !important;
//   margin-right: 4px !important;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     background-color: $white !important;
//     color: #28a745 !important;
//   }
// }

// .deleteBtn {
//   border: 1px solid #dc3545 !important;
//   color: #dc3545 !important;

//   &:hover,
//   &:active,
//   &:focus,
//   &:active:focus,
//   &:active:hover {
//     color: #dc3545 !important;
//   }
// }

// .signatureWrap {
//   display: flex;
//   width: 100%;
//   margin-top: 15px;
//   margin-bottom: 25px;

//   .themeBtn {
//     margin: 0;
//     margin-right: 30px;
//   }

//   .signatureImg {
//     width: 210px;
//     border: 1px solid rgba($black, 0.04);
//     border-radius: 6px;
//   }
// }

// .agentAvatar {
//   width: 32px;
//   min-width: 32px;
//   height: 32px;
//   border-radius: 50%;
//   border: 1px solid rgba($black, 0.05);
//   border-radius: 50%;
//   object-fit: cover;
//   margin-right: 5px;
// }

// .viewRatingWrap {
//   li {
//     border: 0;
//     border-bottom: 1px solid rgba($black, 0.08);
//     border-radius: 0;
//     padding: 12px 0;

//     .star-container {
//       padding-right: 3px !important;
//       padding-left: 0 !important;
//     }

//     .star-ratings {
//       margin-top: -2px;

//       svg {
//         height: 16px !important;
//         width: 16px !important;
//       }
//     }

//     .readMore {
//       padding: 0;
//       flex-shrink: 0;
//       align-self: flex-end;
//       margin: 0;
//       font-weight: 700;
//       margin-bottom: 2px;
//       margin-left: 2px;
//     }
//   }
// }

// .customDropdownMenuNavbar + .dropdown-menu {
//   box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075) !important;
//   border-radius: 6px;
// }

// .customDropdownMenuNavbar + .dropdown-menu .dropdown-item:hover,
// .customDropdownMenuNavbar + .dropdown-menu .dropdown-item:focus {
//   background-color: rgba(7, 184, 225, 0.2);
//   color: $grey !important;
// }

// .customDropdownMenuNavbar + .dropdown-menu .dropdown-item:first-child {
//   border-top-left-radius: 6px;
//   border-top-right-radius: 6px;
// }

// .customDropdownMenuNavbar + .dropdown-menu .dropdown-item:last-child {
//   border-bottom-left-radius: 6px;
//   border-bottom-right-radius: 6px;
// }

// .faqIcon {
//   font-size: 16px;
// }

// .adminTable .table-bordered td,
// .normalTableWrap td {
//   white-space: normal;
//   word-break: break-all;
// }

// .upgradeAccountModal {
//   text-align: center;
//   padding-bottom: 20px !important;

//   img {
//     height: 58px;
//     margin-top: 15px;
//     margin-bottom: 0;
//   }

//   h2 {
//     margin: 15px 0;
//     font-weight: 700;
//     color: $black;
//     font-size: 24px;
//     text-transform: capitalize;
//     line-height: 1.5;
//   }

//   h4 {
//     color: $black;
//     font-weight: 700;
//     margin: 0px 20px;
//     line-height: 1.5;
//     margin-bottom: 20px;
//     font-size: 20px;
//   }

//   .modalBtnSave {
//     height: auto;
//     line-height: 1;
//     padding: 16px 24px;

//     // span {
//     //   display: block;
//     //   height: auto;
//     //   margin-bottom: 6px;
//     // }

//     span {
//       margin-left: 2px;
//       height: auto;
//       font-size: 14px;
//     }
//   }

//   + .modal-footer {
//     flex-direction: column;

//     .modalBtnSave,
//     .modalBtnCancel {
//       width: 280px;
//       height: 60px;

//       div {
//         font-size: 14px;
//         line-height: 14px;
//       }

//       div:first-child {
//         font-size: 20px;
//         line-height: 34px;
//         margin-top: -8px;
//       }
//     }

//     .modalBtnSave {
//       margin-bottom: 15px !important;
//       text-align: center;
//       text-decoration: none;

//       div:first-child {
//         margin-top: 0;
//       }
//     }
//   }
// }

// .clientsPgTableWrap,
// .teamMembersTableWrap {
//   .adminTable .table-bordered td div {
//     word-break: break-all;
//   }
// }

// .alert.alert-danger {
//   background-color: #f8d7da;
//   border-color: #f5c6cb;
//   color: #721c24;
// }

// .ql-toolbar.ql-snow,
// .ql-container.ql-snow {
//   border-color: $borderColor !important;
// }

// .ql-toolbar.ql-snow {
//   border-top-right-radius: 8px;
//   border-top-left-radius: 8px;
// }

// .ql-container.ql-snow {
//   border-bottom-right-radius: 8px;
//   border-bottom-left-radius: 8px;
// }

// .scanbackTxt {
//   label {
//     font-weight: 700;
//   }
// }

// .confirmBtn {
//   height: auto;
//   padding: 2px 6px !important;
//   margin: 4px 0 !important;
//   font-size: 12px;

//   &:hover {
//     background-color: blue !important;
//   }
// }

.bg {
  &-danger {
    border-color: #e98057 !important;
  }

  &-warning {
    border-color: #fbc658 !important;
  }

  &-success {
    border-color: #6bd098 !important;
  }
}

// .detailsPgCard {
//   border: 1px solid $blueBorderColor;
//   border-radius: 10px;
//   box-shadow: none;

//   .card-header {
//     border-color: $blueBorderColor;

//     .card-title {
//       color: $mediumBlue;
//       font-weight: 700;
//     }
//   }

//   &.basicInfo {
//     h6 {
//       margin-bottom: 6px;
//       margin-top: 0;
//     }

//     p {
//       margin-bottom: 20px;
//     }
//   }

//   h6 {
//     color: $mediumBlue;
//     font-weight: 700;
//     margin-bottom: 15px;
//   }

//   p {
//     color: $textColor;
//     margin-bottom: 0px;
//     font-size: 15px;
//   }

//   .customLeftBorder {
//     &:not(:first-child) {
//       border-left: 1px solid $blueBorderColor;
//     }
//   }

//   .card-text {
//     font-size: 17px;
//     margin-bottom: 0;
//     font-weight: 600;
//   }

//   .customBottomBorder {
//     &:not(:last-child) {
//       padding-bottom: 20px;
//       border-bottom: 1px solid $blueBorderColor;
//       margin-bottom: 20px;
//     }
//   }

//   .table {
//     thead {
//       tr {
//         th {
//           border: none !important;
//         }
//       }
//     }
//   }
// }

// .inputGroup {
//   border: 1px solid $borderColor;
//   border-radius: 10px;
//   height: 48px;

//   .input-group-prepend {
//     overflow: hidden !important;
//     border: none;

//     .input-group-text {
//       border: none;
//     }

//     select,
//     input {
//       border: none;
//     }
//   }

//   .form-control {
//     border: none;

//     &:focus {
//       box-shadow: none;
//       border: none !important;
//     }
//   }

//   .css-1s2u09g-control {
//     border: none !important;
//     height: 100%;
//   }

//   .css-yk16xz-control {
//     border-top-right-radius: 10px;
//     border-bottom-right-radius: 10px;
//   }

//   .css-1pahdxg-control {
//     box-shadow: none;
//     border: 0;
//     margin-top: 3px;
//   }

//   .css-tlfecz-indicatorContainer {
//     padding: 8px 4px;
//   }
// }

// .automationWrapper {
//   margin-top: 16px;
//   margin-bottom: 16px;

//   .automationHeader {
//     h6 {
//       margin-bottom: 0;
//       color: #123f5f;
//       font-weight: 500;
//     }

//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding-bottom: 10px;

//     button {
//       color: $themeColor !important;
//       font-weight: 400;
//       margin: 0;
//     }
//   }

//   .automationForm {
//     border-top: 1px dashed $blueBorderColor;

//     .card {
//       box-shadow: none;

//       .card-body {
//         padding: 0;
//         padding-top: 10px;
//       }
//     }
//   }

//   // .automationAction {
//   //   display: flex;
//   //   flex-direction: column;
//   //   align-items: center;
//   //   margin: 20px 0 0;

//   //   .btn-link {
//   //     margin-bottom: 15px;
//   //   }

//   //   .action {
//   //     button {
//   //       height: auto;
//   //       padding: 10px 20px;

//   //       &.btn {
//   //         &-warning {
//   //           border-color: #f6c658 !important;
//   //         }

//   //         &-danger {
//   //           border-color: #e98057 !important;
//   //         }
//   //       }
//   //     }
//   //   }
//   // }
// }

// .badge {
//   &.gray {
//     background: #f5f5f5 !important;
//   }

//   &-secondary {
//     background-color: #6c757d;
//   }
// }

// .userProfile {
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   img {
//     width: 30px;
//     height: 30px;
//     object-fit: cover;
//     border-radius: 50%;
//     margin-right: 5px;
//   }

//   span {
//     font-weight: 600;
//     color: $themeColor;
//   }
// }

// .counter {
//   .text {
//     display: block;
//     width: 100%;
//   }
// }

.basicInfo {
  align-items: center;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;

    li {
      flex-grow: 1;
      flex-shrink: 1;

      h6 {
        color: $mediumBlue;
        font-weight: 700;
        margin-bottom: 6px;
      }

      p {
        color: $textColor;
        margin-bottom: 15px;
        font-size: 15px;
      }
    }
  }

  .userIfno {
    margin-left: 14px;
    flex: 1;

    h3 {
      margin-bottom: 3px;
      font-size: 20px;
      font-weight: 700;
    }

    p {
      color: rgba($black, 0.5);
      margin-bottom: 5px;
    }
  }

  // .uploadProfile {
  //   width: 110px;
  //   height: 110px;
  //   object-fit: cover;
  //   margin-bottom: 0 !important;
  // }
}

.contactInfo {
  border-bottom: 2px dashed rgba($black, 0.08);
  padding-bottom: 10px;
  margin-bottom: 15px;

  h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  ul {
    margin-left: 0 !important;
    padding: 0 !important;
    list-style: none;
    margin-bottom: 10px;
    display: block;

    li {
      border-bottom: 1px solid rgba($black, 0.1);
      padding: 10px 0;

      &:last-child {
        border-bottom-color: transparent;
        padding-bottom: 0;
      }

      span {
        &:first-child {
          font-weight: 700;
          margin-right: 10px;
          width: 60px;
          display: inline-block;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
    color: rgba($black, 0.5);
  }
}

.agentOtherInfo {
  margin-bottom: 15px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }
}

.credentialsWrap {
  padding: 10px;
  border-bottom: 1px dashed rgba($black, 0.2);

  .credentialHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block-end: 10px;
    padding-block-start: 10px;

    p {
      font-size: 17px;
      margin-bottom: 0;
      font-weight: 600;
      color: $textColor;
    }

    button {
      height: auto;
      padding: 10px 20px;
      font-weight: 500;
    }
  }
}

// .confirmBtn {
//   border: none !important;
//   border-radius: 5px;

//   &.bg {
//     &-danger {

//       &:hover,
//       &:focus,
//       &:active:focus,
//       &:active {
//         background-color: #e98057 !important;
//       }
//     }

//     &-primary {

//       &:hover,
//       &:focus,
//       &:active:focus,
//       &:active {
//         background-color: $themeColor !important;
//       }
//     }

//     &-warning {

//       &:hover,
//       &:focus,
//       &:active:focus,
//       &:active {
//         background-color: #fbc658 !important;
//       }
//     }

//     &-success {

//       &:hover,
//       &:focus,
//       &:active:focus,
//       &:active {
//         background-color: #6bd098 !important;
//       }
//     }
//   }
// }

// .manageInstructionWrap {
//   border: 1px solid rgba($black, 0.1);
//   border-radius: 8px;
//   padding: 11px 10px 0;
//   margin-bottom: 15px;
// }

// .scanBackDocWrap {
//   border-top: 1px solid rgba($black, 0.1);
//   border-bottom: 1px solid rgba($black, 0.1);
//   display: flex;
//   padding: 10px 0 11px;
//   background-color: $white;
//   margin-bottom: -1px;

//   div {
//     flex: 1;
//     padding: 0 3px;
//   }

//   label {
//     font-size: 12px;
//   }

//   p {
//     margin-bottom: 0;
//     color: $mediumBlue;
//   }
// }

// .btn-view {
//   color: $themeColor !important;
//   font-weight: 600 !important;
//   font-size: 14px !important;
// }

// table {
//   margin-bottom: 0 !important;
// }

// .quill_editor {
//   .ql-container {
//     height: 100px;
//   }
// }

// .chatPagination .pagination {
//   margin-top: 10px !important;
//   margin-bottom: 0 !important;
// }

// .companyName {
//   display: inline-block;
//   background-color: $themeColor;
//   color: $white;
//   font-size: 16px;
//   margin-bottom: 10px 12px;
//   padding: 10px;
//   border-radius: 10px;
//   font-weight: 600;
// }

// .addDeleteBtn {
//   width: 40px;
//   height: 40px;
//   position: relative;
//   top: 4px;

//   &.btn-danger {
//     border-color: transparent;
//   }
// }

.searchListData {
  position: relative;
}

.customSearchOptions {
  position: absolute;
  top: calc(100%);
  z-index: 9;
  border: 1px solid #e9e9e9;
  min-width: 300px;
}

.customSearchOptions li {
  padding: 10px;
  font-size: 13px;
  border-bottom-style: dashed !important;
  border-bottom-color: #ddd !important;

  &:last-child {
    padding-bottom: 0 !important;
  }
}

.customSearchOptions li:hover {
  background: #f5f5f5;
}

// .clientInfo {
//   h3 {
//     font-size: 22px;
//     color: #2c4199 !important;
//     font-weight: 700;
//     margin-bottom: 20px !important;
//   }

//   li {
//     margin-bottom: 10px;
//     flex: 0 0 25%;
//   }
// }

.companyInfo {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;

  .infoPoint {
    margin-bottom: 20px;
    margin-right: 40px;
  }
}

.standfee {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-right: 15px;
    margin-bottom: 0;

    label {
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

// .agentInfo {
//   h3 {
//     font-weight: 600;
//     font-size: 20px;
//     margin-bottom: 5px;
//     color: $black;
//   }

//   p {
//     margin-bottom: 5px;
//   }
// }

// bulk upload
.uploadInfo {
  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    color: $black;
  }

  ul {
    padding: 0;
    list-style: none;
    margin-top: 0;

    li {
      padding: 10px 0;
      font-size: 16px;
      border-bottom: 1px dashed $borderColor;

      &:last-child {
        padding-bottom: 0;
        border: none;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  &.dublicates {
    h2 {
      margin-bottom: 8px;
    }
  }
}

.addSigner,
.removeSigner {
  border: 1px solid $themeColor;
  border-radius: 50%;
  background-color: $white;
  padding: 0;
  height: 25px;
  width: 25px;
  min-width: 25px;
  line-height: 25px;
  position: absolute;
  top: -18px;
  right: 15px;
  color: $themeColor;
  cursor: pointer;
  z-index: 99;

  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:active:hover {
    border-color: $themeColor !important;
    background-color: $white !important;
    color: $themeColor !important;
  }
}

.removeSigner {
  border: 1px solid $errorColor;
  color: $errorColor;

  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:active:hover {
    border-color: $errorColor !important;
    background-color: $white !important;
    color: $errorColor !important;
  }
}

.customListCompany {
  li:last-child {
    border-bottom: 1px solid $blueBorderColor;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.chipsWrap {
  label + div {
    border-color: $borderColor !important;
    border-radius: 10px !important;
    min-height: 48px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  label + div {
    div:not(:last-child) {
      background-color: #e6e6e6 !important;
      font-size: 90% !important;
      border-radius: 2px !important;
      padding: 3px 6px !important;
      display: flex;
      align-items: center;

      span {
        font-size: 15px !important;
        margin-left: 6px !important;

        &:hover {
          color: #de350b !important;
        }
      }
    }

    input {
      height: unset !important;
    }
  }
}

// .createClosingWrap {
//   .signerCard {
//     position: relative;

//     &:first-child {
//       .removeSigner {
//         right: 52px;
//       }
//     }
//   }
// }

// .customDropdown {
//   .btn {
//     height: 34px;
//     border-radius: 6px;
//     background-color: $white;
//     color: $textColor;
//     font-weight: 500;
//     border-color: $textColor;

//     &:hover,
//     &:active,
//     &:focus,
//     &:active:focus,
//     &:active:hover {
//       background-color: $white !important;
//       border-color: $textColor !important;
//       color: $textColor !important;
//     }

//     &::after {
//       vertical-align: 2px;
//       margin-left: 5px;
//     }
//   }

//   &.show > .btn.dropdown-toggle:focus,
//   .btn.dropdown-toggle {
//     background-color: $white !important;
//     color: $textColor !important;
//   }

//   .dropdown-menu {
//     margin-top: 0;
//     padding: 5px 0;

//     .dropdown-item {
//       padding: 6px 12px;

//       &:hover,
//       &:focus {
//         background-color: $white;
//         color: $textColor !important;
//       }
//     }
//   }
// }

.categoriesWrap {
  display: flex;
  flex-wrap: wrap;

  a {
    border: 1px solid rgba($black, 0.08);
    border-radius: 8px;
    padding: 17px 10px 16px;
    text-align: center;
    min-width: calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
    margin: 7.5px 5px;
    transition: all 0.3s;
    text-decoration: none !important;

    &:hover {
      border: 1px solid $mediumBlue;
      transition: all 0.3s;

      h5,
      p {
        color: $mediumBlue;
      }
    }

    h5 {
      font-size: 16px;
      margin-bottom: 0;
      font-weight: 600;
      color: $black;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $textColor;
      margin-top: 10px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}

.write-to-us-txt,
.write-to-us-txt:hover {
  color: $themeColor !important;
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
  margin-left: 3px;
}

// .searchWrapCategory {
//   border: solid 1px #e0e2e5;
//   border-radius: 8px;
//   margin-bottom: 14px;
//   box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;

//   input {
//     border: 0;
//     box-shadow: none !important;
//     height: 35px;
//     margin-right: 1px;
//     font-size: 14px;

//     &:focus {
//       border-color: transparent;
//     }
//   }

//   .input-group-prepend .btn {
//     background-color: transparent !important;
//     border: 0;
//     box-shadow: none !important;
//     padding: 0.3rem 0.4rem 0.25rem 0.6rem;
//     color: #6f7e90 !important;
//     font-size: 16px;
//   }

//   .input-group-append .btn {
//     border: 0 !important;
//     border-left: 1px solid #e0e2e5 !important;
//     box-shadow: none !important;
//     font-size: 14px;
//     margin-right: -0.5px;
//     padding: 0.3rem 0.75rem;
//     background-color: transparent !important;
//     color: $textColor;
//   }
// }

.topicList {
  a {
    border: 0;
    border-bottom: 1px solid $blueBorderColor;
    padding: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: $deepBlue;

    span {
      color: $mediumBlue;
      font-weight: 600;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

// .customTimeWrap {
//   & + .rdtPicker {
//     right: 0;

//     .rdtCount {
//       height: unset;
//       border: 0 !important;
//       padding: 7px 2px;
//     }
//   }
// }

// .automationCollapseHead {
//   padding: 13px 10px 12px;
//   font-size: 14px;
//   letter-spacing: 0.3px;
//   margin-bottom: 0;
//   cursor: pointer;
//   color: $deepBlue;
//   border-radius: 8px;
//   box-shadow: none;
//   border: 1px solid $blueBorderColor;
//   margin-top: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;

//   i {
//     font-size: 14px;
//     color: $textColor;
//     margin-left: 10px;
//     transition: all 0.3s;
//     margin-top: 3px;
//   }

//   .toggleIcon {
//     transition: all 0.3s;
//     transform: rotate(-180deg);
//     margin-top: 4px;
//   }
// }

// .phoneNumberWrap {
//   position: relative;

//   .input-group-prepend {
//     position: absolute;
//     top: 1px;
//     left: 1px;
//     z-index: 99;
//     height: 46px;

//     .input-group-text {
//       border: 0;
//     }
//   }

//   .form-control {
//     border-left: 1px solid $borderColor !important;
//     border-radius: 10px !important;
//     padding-left: 54px !important;

//     &:focus {
//       border-left-color: $themeColor !important;
//     }
//   }
// }

// .input-group {
//   border: 1px solid $borderColor !important;
//   border-radius: 10px;
//   overflow: hidden;

//   &:focus-within {
//     border-color: $themeColor !important;
//   }

//   .input-group-prepend,
//   .input-group-append {

//     .input-group-text,
//     button {
//       border: none !important;
//     }

//     ~ {
//       .form-control {
//         padding-left: 8px !important;
//       }
//     }
//   }

//   .form-control {
//     border: none !important;
//     border-radius: 0 !important;
//   }
// }

.topicDetailsWrap {
  padding: 0 15px !important;

  .topicImg,
  .topicVideo {
    width: 100%;
    max-width: 900px;
    display: block;
    margin: 25px auto;
    object-fit: cover;
    border: 1px solid rgba($black, 0.08);
    border-radius: 10px;
    max-height: 400px;
  }

  .topicVideo {
    height: 500px;
    max-height: unset;
  }

  .customWysiwyg {
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      max-width: 100% !important;
    }

    img,
    .se-video-container figure,
    .se-video-container iframe {
      max-width: 900px;
      display: block;
      border-radius: 10px;
      margin: 0 auto;
    }

    .se-video-container figure {
      margin: 0 auto 1rem;
      height: 500px !important;
    }

    .se-video-container iframe {
      height: 500px !important;
    }
  }
}

.orderTable {
  .adminTable {
    .table {
      tbody {
        tr {
          td {
            &:last-child {
              width: 60px;
            }
          }
        }
      }
    }
  }

  .react-bs-container-header {
    .table {
      thead {
        tr {
          th {
            &:last-child {
              width: 60px;
            }
          }
        }
      }
    }
  }
}

// note: this project uses reactstrap version -> v8.
// media styles
@media all and (min-width: 1200px) {
  .customHeightDocuments,
  .customHeightExpenses,
  .customHeightExpensesAgent,
  .customHeightDocumentsAgent {
    max-height: 338px;
    overflow: auto;
    scrollbar-color: #e0e0e0 #f5f5f5;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.18);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.18);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #e0e0e0;
    }
  }

  .customHeightExpenses {
    max-height: 392px;
  }

  .customHeightExpensesAgent {
    max-height: 600px;
  }

  .customHeightDocumentsAgent {
    max-height: 375px;
  }

  .filterWrapMob {
    display: none;
  }
}

@media all and (min-width: 768px) {
  // .subscriptionCardHeight {
  //   height: 100%;
  // }
}

@media all and (max-width: 1199.98px) {
  // .logoWrap {
  //   width: 47%;
  // }

  // .authFormWrap {
  //   width: 53%;
  // }

  // .agentPgFilterTab {
  //   background-color: $white;
  //   padding: 15px 15px 5px;
  //   margin-bottom: 20px;
  //   border-radius: 10px;
  //   margin-top: -5px;
  //   border: 1px solid $blueBorderColor;

  //   .react-daterange-picker {
  //     width: 100%;
  //   }
  // }

  // .agentPgFilter {
  //   display: none;
  // }

  // .attorneyDetailsActionArea {
  //   flex-direction: row;

  //   .selectAgent {
  //     margin-bottom: 0;
  //     align-items: center;
  //   }
  // }

  // .clientInfo {
  //   li {
  //     flex: 0 0 33.333%;
  //   }
  // }
}

// @media all and (min-width: 768px) and (max-width: 1199.98px) {

//   .closingDashboardTable,
//   .agentsPgTable {
//     .react-bs-table-bordered {

//       thead>tr>th,
//       .adminTable .table-bordered td {
//         width: 160px;
//       }
//     }
//   }

//   .react-bs-table-bordered,
//   .adminTable {

//     thead>tr>th,
//     .table-bordered td {
//       width: 165px;
//     }
//   }

// @media all and (min-width: 992px) {
//   .footer .copyright {
//     float: unset;
//   }

//   .customMarginSubscriptionTitle {
//     margin-bottom: 74px;
//   }

//   .sidebar-mini {
//     .customSidebarList {
//       .caret {
//         display: none;
//       }
//     }
//   }
// }

@media all and (max-width: 991.98px) {
  .rbc-toolbar {
    padding: 5px 0 30px;

    button {
      padding: 0 6px;
      font-size: 13px;
      height: 28px;
      line-height: 26px;
      margin: 0 2px !important;
    }

    .rbc-toolbar-label {
      font-size: 18px;
    }
  }

  // .signUpPgLogoWrap {
  //   display: none;
  // }

  // .signUpFormWrap {
  //   width: 100%;

  //   .projectLogo {
  //     width: 250px;
  //     display: block;
  //     margin: 0 auto 30px;
  //   }

  //   h3 {
  //     text-align: center;
  //   }
  // }

  // .normalTableWrap {
  //   table .form-control {
  //     min-width: 125px;
  //   }
  // }

  // .attorneyDetailsActionArea {
  //   flex-direction: column;

  //   .selectAgent {
  //     margin-bottom: 12px;
  //     margin-right: 11px;
  //   }
  // }

  // .navbar-absolute.bg-white {
  //   padding-top: 13px;
  //   padding-bottom: 13px;

  //   .navbar-nav {
  //     margin-top: 10px;
  //   }
  // }

  // .nav-open .sidebar-mini .customSidebarList {
  //   img {
  //     margin-right: 10px;
  //   }

  //   span {
  //     display: inline-block !important;
  //   }
  // }

  // .companyInfo {
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //   justify-content: center;
  //   flex-direction: column;
  //   text-align: center;

  //   .infoPoint {
  //     display: block;
  //     margin-right: 0;
  //     margin-bottom: 0px;
  //   }
  // }

  // .clientInfo {
  //   h3 {
  //     text-align: center;
  //   }
  // }

  // .agentContainer {
  //   .row {
  //     flex-direction: column-reverse;

  //     .barCodeWrap {
  //       margin-bottom: 30px;
  //       padding-bottom: 10px;

  //       &::before {
  //         width: 100% !important;
  //         height: 1px !important;
  //         top: auto !important;
  //         bottom: 0;
  //         transform: translate(0, 0) !important;
  //       }
  //     }
  //   }
  // }

  // .categoriesWrap {
  //   a {
  //     min-width: calc(50% - 10px);
  //     max-width: calc(50% - 10px);
  //   }
  // }
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  // .sidebarOpenBtn {
  //   display: initial !important;
  // }

  // .navbar .navbar-nav .nav-item {
  //   margin: 10px 0;
  // }

  // .authFormWrap form {
  //   max-width: 360px;
  // }

  .authPgFooter {
    .row {
      display: flex;
      justify-content: center;
    }

    .credits {
      margin-top: 5px;
    }
  }

  // .signUpFormWrap {
  //   .copyright,
  //   .footer a {
  //     margin-top: 4px;
  //     display: inline-block;
  //   }

  //   .credits {
  //     margin-left: 20px;

  //     a {
  //       margin-top: 0;
  //     }
  //   }
  // }

  // .customMarginSubscriptionTitle {
  //   margin-bottom: 74px;
  // }

  // .mileageLogTable {
  //   .react-bs-table-bordered {
  //     thead > tr > th {
  //       width: 135px;
  //     }
  //   }

  //   .adminTable .table-bordered td {
  //     width: 135px;
  //   }
  // }
}

@media all and (max-width: 767.98px) {
  .main-panel .content {
    padding: 0 15px 10px;
    margin-top: 75px;
    min-height: calc(100vh - 130px);
  }

  // .pgTitleWrap {
  //   h4 {
  //     font-size: 16px;
  //   }
  // }

  // .themeBtn,
  // .themeBtnOutline {
  //   padding: 0 12px;
  //   height: 36px;
  //   line-height: 36px;
  //   border-radius: 6px;
  // }

  // .themeBtnOutline {
  //   line-height: 34px;
  //   max-width: fit-content;
  // }

  // .msgPreview {
  //   font-size: 12px;
  //   margin-left: 10px;
  //   padding: 5px;

  //   img {
  //     height: 24px;
  //     width: 24px;
  //   }
  // }

  .resetBtn {
    margin-right: 5px;
    height: 38px;
    line-height: 38px;
    font-size: 18px;
    width: 42px;
    border-radius: 8px;

    img {
      display: block;
      width: 22px;
      margin: 0 auto;
    }
  }

  // .filterHead {
  //   display: none;
  // }

  // .filterWrapMob {
  //   background-color: $white;
  //   padding: 15px 15px 5px;
  //   margin-bottom: 20px;
  //   border-radius: 10px;
  //   margin-top: -5px;
  //   border: 1px solid $blueBorderColor;

  //   .react-daterange-picker {
  //     width: 100%;
  //   }

  //   .resetBtn {
  //     margin: 0;
  //     height: 44px;
  //     line-height: 44px;
  //     width: 50px;
  //   }

  //   .customColPadding {
  //     &:nth-child(odd) {
  //       padding-right: 7px;
  //     }

  //     &:nth-child(even) {
  //       padding-left: 7px;
  //     }
  //   }
  // }

  .react-bs-table-bordered {
    thead > tr > th {
      width: 165px;
    }
  }

  .react-bs-container-header {
    overflow-x: auto;
  }

  // .adminTable .table-bordered td {
  //   width: 165px;
  // }

  // .normalTableWrap {
  //   td,
  //   thead > tr > th {
  //     min-width: 180px;
  //   }

  //   .showMap {
  //     padding: 0 2px;
  //   }
  // }

  .footer .credits {
    margin-right: 0 !important;
  }

  // .backBtn {
  //   font-size: 14px;
  //   height: 28px;
  //   line-height: 30px;
  // }

  // .customBorderMySchedule {
  //   border-right: 0;
  //   border-bottom: 1px solid $blueBorderColor;
  //   margin-bottom: 30px;
  // }

  // .customFilterMileageLog {
  //   display: flex;
  // }

  // .customFilterExpense {
  //   display: block;
  //   width: 270px;
  //   margin-bottom: 20px;
  //   align-self: flex-start;
  // }

  // .logoWrap {
  //   display: none;
  // }

  // .authFormWrap {
  //   width: 100%;
  //   padding-right: 15px;
  //   padding-left: 15px;

  //   h3 {
  //     font-size: 24px;
  //     text-align: center;
  //   }

  //   .themeBtn {
  //     height: 48px;
  //     line-height: 48px;
  //   }

  //   .signUpForm,
  //   .accountType {
  //     max-width: 525px;
  //   }
  // }

  .projectLogo {
    width: 250px;
    display: block;
    margin: 0 auto 30px;
  }

  // .detailsPgCard {
  //   .customLeftBorder {
  //     &:not(:first-child) {
  //       border-left: 0;
  //     }

  //     &:nth-child(even) {
  //       border-left: 1px solid $blueBorderColor;
  //     }
  //   }

  //   p {
  //     font-size: 15px;
  //   }
  // }

  .closingID {
    padding-left: 29px;
    font-size: 14px;
  }

  .customNavTxt {
    display: initial;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: 0.5px;
    vertical-align: -2.5px;
    margin-left: 10px;
  }

  .faqIcon,
  .settingsIcon {
    vertical-align: -1px;
  }

  .faqSection .card {
    .card-header {
      padding: 10px 12px;
    }

    .card-body {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  // .clientInfo {
  //   ul {
  //     text-align: center;
  //   }

  //   li {
  //     flex: 0 0 50%;
  //   }
  // }

  // .registrationWrap .registrationItem {
  //   label:nth-child(3) {
  //     span {
  //       margin-left: 23px;
  //     }
  //   }
  // }
}

@media all and (max-width: 575.98px) {
  .main-panel .content {
    min-height: calc(100vh - 157px);
  }

  .filterWrapMob {
    .react-daterange-picker {
      width: 98%;
    }
  }

  .footer {
    .row {
      justify-content: center;
    }

    .credits {
      margin: 5px auto 0 !important;
    }
  }

  .card-calendar {
    .card-body {
      padding: 10px;
    }
  }

  .rbc-toolbar {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;

    .rbc-btn-group {
      width: 50%;
      text-align: left;

      button {
        padding: 0 4px;
        font-size: 12px;
      }

      &:last-child {
        text-align: right;
      }
    }

    .rbc-toolbar-label {
      order: 3;
      margin-top: 18px;
    }
  }

  .msgPreview:first-child {
    margin-left: 0;
  }

  // .verifiedCredentials,
  // .insuranceDetail {
  //   li {
  //     padding: 15px 12px;
  //   }
  // }

  .profilePgTab {
    .card .card-title {
      font-size: 15px;
    }
  }

  // .checkBoxButtonOutline,
  // .checkBoxButtonSelected {
  //   width: 64px;
  //   margin-right: 10px !important;
  // }

  .signUpWrapper {
    height: inherit;

    .projectLogo {
      margin-top: 35px;
    }

    .authPgFooter {
      margin-top: 35px;
    }
  }

  .transactionSummary {
    .row {
      div:not(:first-child) {
        border-left: 0;
      }

      div:nth-child(even) {
        border-left: 1px solid $blueBorderColor;
      }
    }
  }

  // .normalTableWrap {
  //   .card-header {
  //     padding: 15px 10px;

  //     .card-title {
  //       font-size: 15px;
  //     }

  //     .btn {
  //       padding: 0 9px;
  //       height: 30px;
  //       line-height: 28px;
  //       width: max-content;
  //     }
  //   }

  //   .addedBy {
  //     width: 100%;
  //     justify-content: space-between;
  //     margin-top: 12px;
  //   }
  // }

  .customList {
    li {
      flex-direction: column;
      padding: 17px 12px;

      > div {
        margin-left: 0;
        margin-top: 5px;
      }

      .form-control {
        width: 100% !important;
      }

      .input-group .form-control {
        width: 210px !important;
      }
    }
  }

  // .customTextAlignment {
  //   text-align: left;
  // }

  // .attorneyDetailsActionArea {
  //   align-items: flex-start;

  //   .selectAgent {
  //     flex-direction: column;
  //     align-items: flex-start;
  //     margin-bottom: 15px;
  //   }

  //   .css-yk16xz-control {
  //     margin-left: 0 !important;
  //     margin-top: 3px;
  //   }
  // }

  .notaryDetails {
    li {
      flex-direction: column;
    }
  }

  .customWidthExpenseBtn {
    width: 122px;
    padding: 0 8px;
  }

  .notificationsWrap li {
    padding: 12px;
  }

  .customExpensesPgBtn {
    padding: 0 8px;
  }

  // .statusReportWrap {
  //   .card-body {
  //     padding: 10px 8px 20px;

  //     &:last-child {
  //       padding-bottom: 2px;
  //     }
  //   }

  //   .tableHeadStatusReport {
  //     padding: 13px 8px;
  //   }

  //   .totalFeesStatusReport {
  //     padding: 15px 8px;

  //     h6 {
  //       font-size: 14px;
  //     }
  //   }
  // }

  // .statusReportTable {
  //   thead > tr > th,
  //   tbody tr td {
  //     padding-left: 8px;
  //     padding-right: 8px;
  //   }
  // }

  // .signatureWrap {
  //   flex-wrap: wrap;
  //   margin-top: 15px;

  //   .signatureImg {
  //     margin-top: 18px;
  //   }
  // }

  // .react-bs-table-pagination {
  //   p {
  //     margin-bottom: 5px;
  //   }
  // }

  // .agentInfo {
  //   .agentImg {
  //     width: 90px !important;
  //     height: 90px !important;
  //   }

  //   .saveBtn {
  //     display: block;
  //     width: 100%;
  //     margin-top: 25px;
  //   }
  // }

  .categoriesWrap {
    a {
      min-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .topicDetailsWrap {
    .topicVideo {
      height: 450px;
      max-height: unset;
    }

    .customWysiwyg {
      .se-video-container figure,
      .se-video-container iframe {
        height: 450px !important;
      }
    }
  }
}

// @media all and (max-width: 480px) {
// .registrationWrap .registrationItem.business {
//   label {
//     &:first-child {
//       span {
//         margin-left: 9px !important;
//       }
//     }

//     span {
//       margin-left: 17px !important;
//     }
//   }
// }

// .registrationWrap .registrationItem.company {
//   label {
//     &:first-child {
//       span {
//         margin-left: 9px !important;
//       }
//     }

//     span {
//       margin-left: 17px !important;
//     }
//   }
// }

// .registrationWrap .registrationItem.notaries {
//   label {
//     &:first-child {
//       span {
//         margin-left: 13px !important;
//       }
//     }
//   }
// }
// }

// .instruction .quill.editor .ql-container.ql-snow {
//   height: 200px;
//   overflow-y: auto;
// }

//table
// .TableData {
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   background-color: #fff;
//   border-radius: 10px;
//   padding: 20px;
//   border: 1px solid #daedf6;

//   li {
//     display: flex;
//     justify-content: space-between;
//     color: #2c4199;
//     font-weight: 600;
//     font-size: 16px;
//     padding: 10px 0px;
//     border-bottom: 1px dashed #daedf6;

//     &:first-child {
//       padding-top: 0;
//     }

//     &:last-child {
//       border-bottom: none;
//       padding-bottom: 0;
//     }
//   }
// }

// agent about
// .agentCompanyLogo {
//   margin-bottom: 30px;

//   img {
//     height: 50px;
//     margin: auto;
//     display: block;
//   }
// }

// .agentCoverPhoto {
//   height: 200px;
//   overflow: hidden;
//   background-color: #f5f5f5;
//   border-radius: 10px;
//   overflow: hidden;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }

// .barCodeWrap {
//   h2 {
//     font-size: 16px;
//     margin-bottom: 15px;
//     font-weight: 700;
//   }

//   canvas {
//     border-radius: 10px;
//     width: 200px !important;
//     height: 200px !important;
//   }
// }

// .ContactList {
//   padding: 0;
//   margin: 0;
//   list-style: none;
//   padding-right: 20px;

//   li {
//     position: relative;
//     padding: 10px 0;
//     padding-left: 30px;
//     font-weight: 600;
//     border-bottom: 1px dashed $borderColor;

//     &:last-child {
//       padding-bottom: 0;
//       border: none;
//     }

//     &:first-child {
//       padding-top: 0;
//     }

//     .icon {
//       position: absolute;
//       left: 0px;
//       width: 20px;
//       height: 20px;
//       background-color: $themeColor;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-right: 10px;
//       border-radius: 50px;

//       i {
//         color: $white;
//         font-size: 12px;
//         border-radius: 50px;
//       }
//     }

//     span {
//       width: 100%;
//       display: block;
//       color: $textColor;
//       font-size: 12px;
//     }
//   }
// }

// .credentialList {
//   padding: 0;
//   margin: 0;
//   list-style: none;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-wrap: wrap;

//   li {
//     position: relative;
//     padding: 10px 0;
//     font-weight: 600;
//     border-bottom: 1px dashed $borderColor;
//     width: 49%;

//     &:last-child {
//       padding-bottom: 0;
//       border: none;
//     }
//   }
// }

// .agentContainer {
//   padding: 15px;

//   .agentInfo {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     margin-bottom: 6px;
//   }

//   .row {
//     background-color: #f8f9fb;
//     padding: 15px;
//     margin: 0;
//     border-radius: 10px;

//     .col-md-6 {
//       padding: 0;
//     }

//     .barCodeWrap {
//       position: relative;

//       &::before {
//         content: "";
//         position: absolute;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         width: 1px;
//         height: 90%;
//         background: rgba($black, 0.2);
//       }

//       p {
//         font-size: 13px;
//       }
//     }
//   }
// }

// .registrationWrap {
//   margin-top: 20px;
//   h2 {
//     font-weight: 600;
//     font-size: 18px;
//     margin-bottom: 10px;
//   }

//   .registrationItem {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     flex-wrap: wrap;
//     // margin-bottom: 20px;

//     label {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       margin-right: 15px;
//       margin-bottom: 15px !important;
//       font-size: 16px;
//       cursor: pointer;

//       span {
//         border: 1px solid $themeColor;
//         padding: 8px 15px;
//         border-radius: 10px;
//         font-weight: 600;
//         margin-left: 15px;
//       }

//       input {
//         &:checked~span {
//           background-color: $themeColor;
//           color: $white;
//         }
//       }
//     }

//     &.notaries {
//       label {
//         &:first-child {
//           margin-right: 31px;

//           span {
//             margin-left: 20px;
//           }
//         }
//       }
//     }
//   }
// }

// .chooseType {
//   font-size: 16px !important;
//   // margin-bottom: 20px;
//   color: $black !important;
//   cursor: pointer;

//   span {
//     font-weight: 500 !important;
//     margin-left: 4px;
//     display: inline-block;

//     i {
//       margin-left: 4px;
//       font-size: 12px;
//     }
//   }

//   button {
//     background-color: transparent;
//     color: $black !important;
//     border-color: transparent;
//     padding: 0;
//     font-weight: 500;
//   }
// }

// .createInfo {
//   background-color: #f5f5f5;
//   padding: 30px;
//   margin: 10px 0 !important;
//   border-radius: 4px;
//   font-size: 20px;
//   font-weight: 600;
//   color: #000 !important;
//   text-align: center;
// }

// .profileInfo {
//   .uploadProfile {
//     margin-top: -60px !important;
//     border-color: $white;
//     box-shadow: 0 10px 10px 0 rgba($black, 0.1);
//     margin-bottom: 40px !important;
//   }
// }

// .tab-content .tab-pane {
//   .profileInfo {
//     .uploadProfile {
//       margin-top: 0 !important;
//     }
//   }
// }

// .searchDropdown {
//   .reactselect {
//     height: 100%;

//     .css-1pahdxg-control {
//       margin-top: 0 !important;
//       padding-top: 0 !important;
//     }
//   }
// }

// .ReferralLink {
//   ul {
//     padding: 0;
//     margin: 0;
//     list-style: none;
//   }
// }

// .EmailBodyContent {
//   table {
//     tr {
//       > td {
//         padding: 10px;

//         table {
//           max-width: inherit !important;
//         }
//       }
//     }
//   }
// }

// .automationCollaseBody {
//   height: auto !important;
// }
